/**
 * @param {React.ReactNode} props.children
 * @returns Row container
 */
function Row({ children }) {
    return (
        <ul className="grid overflow-scroll">
            {children}
        </ul>
    );
}

export default Row;
