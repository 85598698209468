import { RecognizedText } from './recognition/v1';

export function compareLegalWithRecognizedMoves(
  legalMoves: readonly string[],
  recognizedMoveText: readonly RecognizedText[]
): RecognizedText[] {
  return recognizedMoveText.flatMap((rm) =>
    legalMoves
      .filter((lm) => compareMoves(rm.t, lm, false))
      .map((x) => ({
        t: x,
        p: rm.p
      }))
  );
}

/**
 * Ermittle erlaubte Züge welche ählich sind zu den erkannten Zügen.
 *
 * @param legalMoves Liste der legalen Züge für diesen Halbzug
 * @param recognizedMoveText Liste der erkannten Texte
 * @returns Liste der legalen Züge welche identisch sind mit mindestens einem der erkannten Texte, maximal mit einem Zeichen Unterschied...
 */
export function compareLegalWithRecognizedMovesOneCharWrongAllowed(
  legalMoves: readonly string[],
  recognizedMoveText: readonly RecognizedText[]
): RecognizedText[] {
  const combinedArray = recognizedMoveText.flatMap((rm) =>
    legalMoves.map((lm) => ({
      rm: rm,
      lm: lm
    }))
  );

  return combinedArray
    .filter(
      (item) =>
        item.lm.length > 1 &&
        item.lm.length == item.rm.t.length &&
        Array.from(item.lm).filter((char, index) => char !== item.rm.t[index])
          .length == 1
    )
    .map((x) => ({ t: x.lm, p: x.rm.p }));
}

/**
 * Cache für MoveMatch-Objekte...
 */
const moveMatches = new Map<string, RegExpExecArray | null>();

function getMoveMatch(move: string) {
  if (moveMatches.has(move)) return moveMatches.get(move) ?? null;

  const match = MOVE_REGEX.exec(move);
  moveMatches.set(move, match);
  return match;
}

/**
 *
 * @param move1 Der erste Schreiber. Bei symetric = false beinhaltet dies den erkannten Text
 * @param move2 Der zweite Schreiber. Bei symetric = false beinhaltet dies den Legal-Move-SAN
 * @param symetric True = Beide Texte sind erkannte Texte. False = der erste Parameter beinhaltet erkannten Text, der zweite den Legal Move
 * @returns
 */
export function compareMoves(move1: string, move2: string, symetric = true) {
  const m1 = getMoveMatch(move1)?.groups;
  const m2 = getMoveMatch(move2)?.groups;

  return (
    m1 &&
    m2 && // Nur wenn beide Züge zerlegt werden können
    (m1['RO'] || m2['RO']
      ? m1['RO'] == m2['RO'] // Falls es eine große oder kleine Rochande ist. Ansonsten ab nächster Zeile
      : m1['FF'] == m2['FF'] && // Enweder beide geben keine Quell-Figur an, oder beide geben die gleiche Quell-Figur an.
        (m1['FC'] ?? m2['FC']) == (m2['FC'] ?? m1['FC']) && // Nur wenn beide eine Quellspalte angeben, muss diese übereinstimmen
        (m1['FR'] ?? m2['FR']) == (m2['FR'] ?? m1['FR']) && // Nur wenn beide eine Quellzeile angeben, muss diese übereinstimmen
        (symetric || // Entweder es wird kein SAN-Abgleich gemacht, weil zwei Schreiber verglichen werden
          m2['BT'] || // Oder beim SAN handelt es sich um einen Schlagzug, dann soll die ganze Regel nicht angewendet werden.
          !(m1['BT'] || (!m1['FF'] && m1['FC'] && m1['FC'] != m1['TC']))) && // Oder der Schreiber hat keinen Schlagzug angegeben
        (m1['TF'] ?? m2['TF']) == (m2['TF'] ?? m1['TF']) && // Nur wenn beide eine Ziel-Figur angeben, muss diese übereinstimmen.
        (m1['TC'] ?? m2['TC']) == (m2['TC'] ?? m1['TC']) && // Nur wenn beide eine Zielspalte angeben, muss diese übereinstimmen
        (m1['TR'] ?? m2['TR']) == (m2['TR'] ?? m1['TR']) && // Nur wenn beide eine Zielzeile angeben, muss diese übereinstimmen
        m1['PF'] == m2['PF']) // Enweder beide geben keine Bauernwandlung an, oder beide geben die gleiche Bauernwandlung an.
  );
}

export function extractMoveText(moveText: string): string | null {
  const match = getMoveMatch(moveText);
  if (!match) {
    return moveText + '?'; // Das Fragezeichen ist nur eine Debug-Hilfe. Wenn aus dem erkannten Text kein Zug gemachted werden kann, wird in der Anwendunf auch kein Button angeboten werden können, weil kein Vergleich zu einem Legal-Move möglich ein wird....
  }
  return match[0];
}

const MOVE_REGEX =
  /(?:(?<RO>O-?O(?:-?O)?)|(?=[KQRBNa-hx])(?:(?<FF>[KQRBN])?(?<FC>[a-h])?(?<FR>[1-8]?)?)(?:(?<BT>x)|-)?(?=[KQRBNa-h])(?<!^[a-h][18])(?:(?<TF>[KQRBN])?(?<TC>[a-h])?((?<=[a-h])(?<TR>[1-8]?))?)=?(?<PF>[QRBN])?)[#+]?/;
