import { HalfMoveWriter } from '../HalfMoveWriter';
import { ICommand } from '../ICommand';

export class SetLastMoveCommand implements ICommand {
  #halfMoveWriter: HalfMoveWriter;

  constructor(halfMoveWriter: HalfMoveWriter) {
    this.#halfMoveWriter = halfMoveWriter;
  }

  public get text() {
    if (this.#halfMoveWriter.halfMove.gameLogic.writer2)
      if (this.#halfMoveWriter.gameLogicWriter.writer == 1) return '⚐₁';
      else return '⚐₂';
    else return '⚐';
  }

  public execute(): void {
    this.#halfMoveWriter.setAsLastMove();
  }

  public toString = (): string => {
    return this.text;
  };

  public get prominent() {
    return false;
  }

  public get pressed() {
    const calculated = this.#halfMoveWriter.gameLogicWriter.lastMoveCalculated;
    return (
      this.#halfMoveWriter.rowLocation != undefined &&
      calculated != undefined &&
      calculated.isEqual(this.#halfMoveWriter.rowLocation)
    );
  }
}
