import { Rectangle } from './recognition/v1/Rectangle';
import { SheetIndex } from './SheetIndex';
import { HalfMove } from './HalfMove';
import { MoveShift } from './MoveShift';
import { RowIndex } from './RowIndex';
import { RecognizedText } from './recognition/v1';
import { RowLocation } from './RowLocation';
import { GameLogicWriter } from './GameLogicWriter';
import { extractMoveText } from './HalfMoveMatcher';
import { NotationName } from './NotationName';

/**
 * HalfMoveWriter repräsentiert die Formaularspezifischen Daten eines Halbzuges
 * für einen der beiden Schreiber.
 *
 * Jeder {@link HalfMove} hat zwei dieser Objekte unter sich über die der Anwender
 * an die Daten des jeweiligen Schreibers zu diesem Halbzug kommt.
 */
export class HalfMoveWriter {
  public setAsLastMove() {
    const b = this.gameLogicWriter.writer == 2;
    this.gameLogicWriter.setLastMoveFixedInternal(this.rowLocation, !b);

    let hm = this.#halfMoveWriterAfter;
    while (hm != null) {
      hm.#rowLocationCandidate = undefined;
      hm.#updateRecognizedMoves(true);
      hm = hm.#halfMoveWriterAfter;
    }

    if (b && !this.#halfMove.gameLogic.calculatePossibleShifts())
      this.halfMove.gameLogic.halfMoves[0].calculate();

    this.#halfMove.gameLogic.selectFirst();
  }

  /**
   * Zeiger auf den {@linkcode HalfMove} zu dem dieses {@linkcode HalfMoveWriter}-Objekt gehört
   */
  #halfMove: HalfMove;

  /**
   * Zeiger auf das {@linkcode HalfMoveWriter}-Objekts des vorherigen Halbzuges des gleichen
   * Schreibers.
   *
   * Beim ersten Halbzug des Spiels (1 Weiß) hat dieses Feld den Wert ```null```.
   */
  #halfMoveWriterBefore: HalfMoveWriter | null = null;

  /**
   * Zeiger auf das {@linkcode HalfMoveWriter}-Objekts des nächsten Halbzuges des gleichen
   * Schreibers.
   *
   * Beim letzten Halbzug in {@linkcode GameLogic.halfMoves} hat dieses Feld den Wert
   * ```null```.
   */
  #halfMoveWriterAfter: HalfMoveWriter | null = null;

  /**
   * Backing-Field zu {@linkcode shift}
   */
  #shift: MoveShift = 0;

  /**
   * @private
   *
   * Liefert das zugehörige Halbzug-Objekt.
   */
  public get halfMove() {
    return this.#halfMove;
  }

  /**
   * @private
   * Erstellt ein neues {@linkcode HalfMoveWriter}-Objekt für den angeegebenen Schreiber und
   * Halbzug.
   *
   * Hinweis: Dieser Konstruktur ist nicht die externe verwendung gedacht.
   *
   * @param halfMoveWriterBefore Hier muss der zum gleichen Schreiber passende halfMoveWriter des vorherigen {@link HalfMove} übergeben
   * @param halfMove Hier muss der zugehörge {@link HalfMove} übergeben werden
   * @param writer Angabe des Schreibers für den dieser HalfMoveWriter gilt.
   */
  public constructor(
    halfMoveWriterBefore: HalfMoveWriter | null,
    halfMove: HalfMove,

    /**
     * Verwies auf das zugehöroge {@linkcode GameLogicWriter}-Objekt- des zu diesem ```HalfMoveWriter```-Objekts
     */
    public readonly gameLogicWriter: GameLogicWriter
  ) {
    this.#halfMoveWriterBefore = halfMoveWriterBefore;
    this.#halfMove = halfMove;
    if (halfMoveWriterBefore) halfMoveWriterBefore.#halfMoveWriterAfter = this;
  }

  /**
   * Gibt die vom Erfasser definierte Verschiebung der Formularzellen zurück.
   */
  public get shift() {
    return this.#shift;
  }

  /**
   * Setzt die Anzahl der Veschiebungen zwischen dem echten Spiel und dem Forumlar welches an dieser Stelle des Formulars
   * verursacht wurde...
   *
   * @param value Relativ-Angabe welchen Versatz an dieser Stelle das Formular des Schreibers neu dazubringt.
   *
   * - 1 wenn ein Zug an dieser Stelle eingefügt werden muss weil der Erfasser diesen Zug auf dem Formular vergessen hat.
   * - 0 wenn der Erfasser den Zug relativ gesehen zum vorherigen Zug korrekt erfasst hat,
   * - -1 wenn der Erfasser eine Zeile durchgestrichen hat
   * - -2 wenn der Erfasser 2 Zeilen durchgestrichen hat etc.
   *
   * Dabei ist zu beachten dass es hier nicht um den Gesamtversatz geht den der Schreiber zum realen Spiel hat
   * an dieser Stelle, sondern nur der Versatz den der Schreiber bei diesem Zug hat.
   * Das ist auch der Grund warum als positiver Versatz nur maximal 1 erlaubt ist, denn jeder Spielzug kann vom
   * Schreiber nur einmal vergessen worden sein.
   * Umgekehrt kann der Schreiber mehrere Zellen (Zeilen) fälschlicherweise durchgestrichen haben bevor er den
   * Zug dann richtig notiert hat.
   */
  public set shift(value: MoveShift) {
    if (this.setShiftInternal(value)) {
      // Das bedeutet aber auch dass eine Neu-Berechnung notwendig wird.
      this.#halfMove.calculate();
    }
  }

  /**
   * @private
   * @param value
   * @returns
   */
  setShiftInternal(value: MoveShift) {
    if (this.#shift != value) {
      // Setze den neuen Wert
      this.#shift = value;

      // Aktualiere die von hier ab folgenden Zellen dieses Spielers.
      this.updateRowLocation(true);

      return true;
    }
    return false;
  }

  /**
   * Liefert die Box in der dieser Schreiber diesen Hal-Zug erfasst hat.
   *
   * Kann null liefern wenn der Schreiber diesen Halbzug in seinem Formularen nicht erfasst
   * hat, also entweder vergessen hat, oder wenn der Schreiber die letzten Züge nicht mehr
   * erfasst hat weil das Blatt voll war und er kein weiteres angefangen hat.
   */
  public get box(): Rectangle | null {
    if (this.rowLocation != undefined)
      return this.gameLogicWriter.sheets[this.rowLocation.sheetIndex].boxes[
        this.rowLocation.rowIndex
      ];
    else return null;
  }

  /**
   * Backing-Field zu {@linkcode recognizedTexts}.
   */
  #recognizedTexts: RecognizedText[] | null = null;

  /**
   * @private
   *
   * Liefert über alle Netze hinweg die erkannten Texte inklusive der Wahrscheinlichkeiten
   * zu diesem Halbzug von diesem Schreiber.
   */
  public get recognizedTexts() {
    return this.#recognizedTexts;
  }

  /**
   * Backing-Field zu {@linkcode rowLocation}
   */
  #rowLocationCandidate?: RowLocation;

  /**
   * Beschreibt die Zelle dieses Schreibers der dem {@linkcode halfMove} aktuell zugeordnet ist.
   *
   * Falls shift == 1 ist, ist ```rowLocation``` als ```undefined``` definiert.
   */
  public get rowLocation() {
    return this.shift == 1 ? undefined : this.#rowLocationCandidate;
  }

  /**
   * @private
   * Aktialisiert die Eigenschaft {@linkcode rowLocation}
   * wenn z.B. die Eigenschaft {@linkcode shift} verändert wird.
   * @param refreshRecognizedMovesOnHalfMove Gibt an ob auch der Refresh auf Ebene des zugehörigen {@linkcode HalfMove} ausgelöst werden soll.
   * Das macht beim Initialisieren nicht immer Sinn, weil sonst Code zu oft ablaufen würde...
   */
  public updateRowLocation(refreshRecognizedMovesOnHalfMove: boolean) {
    if (this.#halfMoveWriterBefore) {
      // Ermittle das Sheet des Vorgänger-Zuges
      const lastRowLocationCandidate =
        this.#halfMoveWriterBefore.#rowLocationCandidate;
      const sheets = this.gameLogicWriter.sheets;

      // Wenn bereits der letzte Zug nicht mehr auf irgendeinem Formular platz hatte,
      // dann können auch alle weiteren Züge bei diesem Schreiber nicht mehr
      // plath haben..
      if (lastRowLocationCandidate == undefined)
        this.#rowLocationCandidate = undefined;
      else {
        const lastSheet = sheets[lastRowLocationCandidate.sheetIndex];

        // Ermittle den letzten Zug bei dem noch ein rowIndex gesetzt wurde
        if (
          lastRowLocationCandidate.rowIndex + 1 - this.shift >=
          lastSheet.boxes.length
        ) {
          if (sheets.length > lastRowLocationCandidate.sheetIndex + 1)
            this.#rowLocationCandidate = new RowLocation(
              (lastRowLocationCandidate.sheetIndex + 1) as SheetIndex,
              -this.shift as RowIndex
            );
          else {
            // Wenn der letzte Zug des letzten Sheets bei diesem Schreiber bereits
            // verwendet wurde, dann geht nur noch null...
            this.#rowLocationCandidate = undefined;
          }
        } else
          this.#rowLocationCandidate = new RowLocation(
            lastRowLocationCandidate.sheetIndex,
            (lastRowLocationCandidate.rowIndex + 1 - this.shift) as RowIndex
          );
      }
    } else this.#rowLocationCandidate = new RowLocation(0, 0);

    // Bilder nach dem letzten Zug für diesen Schreiber sollen nicht gezogen werden, daher soll
    // in dem Fall dann die RowLocation undefined sein. Das ist einfach aus optischen Gründen sinnvoll
    // damit keine Leerzellen angezeugt werden.
    if (this.#isAfterEnd()) {
      this.#rowLocationCandidate = undefined;
    }
    // Aktualisiere die Liste der für diesen Schreiber erkannten Züge auf Basis der Texte des neuen rowIndex
    this.#updateRecognizedMoves(refreshRecognizedMovesOnHalfMove);

    // Aktualisiere nur den Folgeschritt.
    this.#halfMoveWriterAfter?.updateRowLocation(
      refreshRecognizedMovesOnHalfMove
    );
  }

  /**
   *
   * @returns `true`, wenn dieser Halbzug nach dem letzen Halbzug des Schreibers liegt, sonst `false`
   */
  #isAfterEnd() {
    return (
      this.#rowLocationCandidate == undefined ||
      (this.gameLogicWriter.lastMoveFixed != undefined &&
        this.#rowLocationCandidate.isGreaterThan(
          this.gameLogicWriter.lastMoveFixed
        ))
    );
  }

  /**
   *
   * @returns `true` wenn es sich um den letzten Zug oder einen Zug nach dem letzten Zug dieses Schreiberes
   * handelt.
   */
  public isEnd() {
    return (
      this.#rowLocationCandidate == undefined ||
      (this.gameLogicWriter.lastMoveFixed != undefined &&
        this.#rowLocationCandidate.isEqualOrGreaterThan(
          this.gameLogicWriter.lastMoveFixed
        ))
    );
  }

  #transformNotationToKQRBN(move: string) {
    let translatedMove = move;
    for (let i = 0; i <= 4; i++) {
      translatedMove = translatedMove.replaceAll(
        this.gameLogicWriter.notation[i],
        NotationName.KQRBN[i]
      );
    }
    // Sonderfälle: ersetze C mit c, wenn die Notaion an keiner Stelle ein großes C beinhalet:
    if (this.gameLogicWriter.notation.indexOf('C') == -1)
      translatedMove = translatedMove.replaceAll('C', 'c');

    // Ersetze Ş mit S, falls die Notation kein Ş beinhalt aber ein S:
    if (
      this.gameLogicWriter.notation.indexOf('Ş') == -1 &&
      this.gameLogicWriter.notation.indexOf('S') > -1
    )
      translatedMove = translatedMove.replaceAll('Ş', 'S');

    // Und umgekehrt: Ersetze S mit Ş, falls die Notation kein S beinhalt aber ein Ş:
    if (
      this.gameLogicWriter.notation.indexOf('S') == -1 &&
      this.gameLogicWriter.notation.indexOf('Ş') > -1
    )
      translatedMove = translatedMove.replaceAll('S', 'Ş');

    return translatedMove;
  }

  /**
   * Aktualisiert den Inhalt des {@linkcode recognizedTexts}-Feldes.
   *
   * @param refreshRecognizedMovesOnHalfMove Gibt an, ob nach einer Aktualisierung der
   * {@linkcode recognizedTexts} dieses Schreibers auch die Neu-Berechnung der Eigenschaft
   * {@linkcode HalfMove.recognizedTexts}-Eigenschaft direkt ausgelöst werden soll oder nicht.
   * Wenn Formulare beider Schreiber vorhanden sind, dann macht die Aktualisierung ggf. erst
   * nur bei der Aktualisierung von Schreiber 2 Sinn, da es sonst doppelt ablaufen würde...
   */
  #updateRecognizedMoves(refreshRecognizedMovesOnHalfMove: boolean) {
    const rl = this.rowLocation;
    if (rl != undefined) {
      const notations = this.gameLogicWriter.sheets[rl.sheetIndex].notations;
      this.#recognizedTexts = notations
        .flatMap((x) => x.models)
        .map((x) => x.halfMoves[rl.rowIndex])
        .flatMap((x) => x.r)
        .sort((x, y) => {
          const ret = y.p - x.p;
          if (ret != 0) return ret;
          else return x.t.length - y.t.length;
        })
        .map(
          (x) =>
            ({
              t: extractMoveText(this.#transformNotationToKQRBN(x.t)),
              p: x.p
            }) as RecognizedText
        )
        .filter((x) => x.t.length > 1); // Erkannte Texte mit nur einem Buchstaben führt gerne zu zu vielen Treffern, daher erst mal weglassen.; // Entferne irrelevante Texte die nicht zum Zug gehören....
      // Achtung!!! Durch das entfernen von unnötigen Texten kann es zu einer Dopplung kommen, ggf. muss diese hier noch behoben werden...
    } else this.#recognizedTexts = null;

    if (refreshRecognizedMovesOnHalfMove)
      this.#halfMove.updateRecognizedTexts();
  }

  /**
   * Veringert den Wert der {@linkcode shift}-Eigenschaft um eins.
   */
  public shiftUp() {
    if (this.shiftUpAllowed) {
      this.shift--;
    }
  }

  /**
   * Erhöht den Wert der {@linkcode shift}-Eigenschaft um eins.
   */
  public shiftDown() {
    if (this.shiftDownAllowed) {
      let hmw = this as HalfMoveWriter;
      while (hmw.shift == 1)
        if (hmw.#halfMoveWriterAfter) hmw = hmw.#halfMoveWriterAfter;
        else return;

      hmw.shift++;
    }
  }

  /**
   * Gibt an, ob der Aufruf von {@linkcode shiftUp()} aktuell erlaubt sein soll.
   *
   * Die UI muss diese Information verwenden um den entsprechenden Button zu deaktivieren.
   */
  public get shiftUpAllowed() {
    return true;
  }

  /**
   * Gibt an, ob der Aufruf von {@linkcode shiftDown()} aktuell erlaubt sein soll.
   *
   * Die UI muss diese Information verwenden um den entsprechenden Button zu deaktivieren.
   */
  public get shiftDownAllowed() {
    return true;
  }
}
