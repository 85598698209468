import React from "react";
import { useTranslation } from "react-i18next";

import { useAppContext } from "../AppProvider";
import useChessEntity from "../api/useChessEntity";
import { useImageProcessing } from "../api/useImageProcessing";
import Icon from "../shared/components/Icon";
import Button from "../shared/components/buttons/Button";
import Container from "../shared/components/layout/Container";
import MobileWrapper from "../shared/components/layout/MobileWrapper";
import ViewHeading from "../shared/components/views/ViewHeading";
import { useGameCacheContext } from "../shared/stepview/context/GameCacheProvider";
import { useGameDataContext } from "../shared/stepview/context/GameDataProvider";
import { useStepNavigationDispatchContext } from "../shared/stepview/context/StepNavigationProvider";
import UploadInfo from "./UploadInfo";
import useKeepAlive from "../api/useKeepAlive";

/**
 * @returns The main upload view
 */
function Upload() {
    const { t } = useTranslation();
    const { setSnack, credits } = useAppContext();
    const { setUpdateCache } = useGameCacheContext();
    const {
        player_1,
        player_2,
        updatePlayer,
        setGameId,
        folder,
        sharedFolders,
    } = useGameDataContext();
    const { submitImages } = useImageProcessing();
    const navigate = useStepNavigationDispatchContext();
    const { updateStatus } = useChessEntity();
    const { keepAlive } = useKeepAlive();

    /**
     * Function to check if the submit is valid.
     * @returns {boolean}
     */
    const isValid = () => {
        const { files: p1Files } = player_1;
        const { files: p2Files } = player_2;

        if (p1Files.length === 0 && p2Files.length === 0) {
            return false;
        }

        return true;
    };

    /**
     * Function to submit the upload.
     */
    const processImages = () => {
        submitImages(player_1, player_2).then(
            (data) => {
                if (data.player_1.data === 0 || !data.player_1.data[0]) {
                    setSnack({
                        message: t("error.imageUpload"),
                        isError: true,
                    });
                }

                updatePlayer("player_1", {
                    files: data.player_1.p1Files.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    ),
                    data: data.player_1.data,
                });

                updatePlayer("player_2", {
                    files: data.player_2.p2Files.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    ),
                    data: data.player_2.data,
                });
                const gameId = data.post_id;
                setGameId(gameId);
                updateStatus(data.post_id, 0);
                setUpdateCache(true);

                const isShared =
                    (sharedFolders || []).findIndex(
                        (shared) => shared.value === folder
                    ) !== -1;
                if (isShared) {
                    // is shared game
                    keepAlive(gameId);
                }
            },
            () => {
                setSnack({
                    message: t("error.imageUpload"),
                    isError: true,
                });
            }
        );
        navigate({ type: "next" });
    };

    return (
        <Container>
            <div className="flex flex-col-reverse sm:flex-col gap-2">
                <div className="flex flex-col gap-2">
                    <ViewHeading
                        heading={t("step1.title")}
                        hintTitle={t("hint.step1.title")}
                        hintContent={t("hint.step1.content")}
                    />
                    <UploadInfo className="self-end" />
                </div>
                <MobileWrapper heightCls="h-18">
                    {credits > 0 ? (
                        <Button
                            disabled={!isValid()}
                            onClick={processImages}
                            className="mr-0 tablet:mr-auto mt-0 tablet:mt-6"
                        >
                            {t("step1.submit")}
                            <Icon name="circleArrowRight" />
                        </Button>
                    ) : (
                        <div className="flex flex-col gap-2">
                            {t("step1.credits.info")}
                            <div className="wp-block-buttons">
                                <a
                                    className="wp-block-button is-style-outline tablet:mr-auto mt-0 tablet:mt-6"
                                    href={process.env.REACT_APP_DASHBOARD_LINK}
                                >
                                    <span className="wp-element-button">
                                        {t("step1.credits.button")}
                                    </span>
                                </a>
                            </div>
                        </div>
                    )}
                </MobileWrapper>
            </div>
        </Container>
    );
}

export default Upload;
