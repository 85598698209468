import React from "react";

import ChessPreview from "../../pgnedit/ChessPreview";
import Tabs from "../../shared/components/form/Tabs";
import { useStepNavigationContext } from "../../shared/stepview/context/StepNavigationProvider";
import { useTabContext } from "../../shared/stepview/context/TabProvider";
import UploadField from "./UploadFields";

/**
 *
 * @param {{label: String, value: String | Number, icon: String}[]} props.tabs
 * @returns Tabbed preview of uploaded images
 */
export default function UploadPreview({ tabs, className, ...props }) {
    const { getActivePath } = useStepNavigationContext();
    const { path } = getActivePath();
    const { tab } = useTabContext();

    return (
        <div
            {...props}
            className={
                "tablet:sticky tablet:top-0 lg:self-start w-full tablet:pt-[80px] " +
                (path === "pgnedit"
                    ? "max-w-sm tiny:max-w-xl lg:max-w-[500px] "
                    : "tablet:max-w-[500px] ") +
                (className ? className : "")
            }
        >
            <>
                <Tabs tabs={tabs} />
                <div className="flex w-full gap-2">
                    {tabs.map((tabConfig) =>
                        tabConfig.value !== "chess" ? "" : (
                            <span
                                key={tabConfig.value}
                                className="flex-1 text-center mb-3 text-sm text-gray-500 uppercase"
                            ></span>
                        )
                    )}
                </div>
                {tab.includes("player") && <UploadField name={tab} />}
                {tab.includes("chess") && <ChessPreview />}
            </>
        </div>
    );
}
