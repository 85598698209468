export enum NotationName {
  KDTLS = 'KDTLS', // Deutsch, Dänisch, Slowenisch, Norwegisch, Mazedonisch, Kroatisch, Serbisch
  KDTLP = 'KDTLP', // Niederländisch, Belgisch, Luxemburgisch
  KDTLR = 'KDTLR', // Finnisch, Afrikaans
  KDTLH = 'KDTLH', // Schwedisch
  KDHBR = 'KDHBR', // Isländisch
  KDTFS = 'KDTFS', // Bulgarisch (Lateinische Schreibweise)
  KQRBN = 'KQRBN', // Englisch, Japanisch, Chinesisch, Koreanisch, Lateinisch
  RDTFC = 'RDTFC', // Französisch
  RDTBC = 'RDTBC', // Portugisisch, Galizisch
  RDTAC = 'RDTAC', // Spanisch, Italienisch
  RDTNC = 'RDTNC', // Rumänisch
  RDTAS = 'RDTAS' // Sizilianisch

  // Die nicht verwendeten Enum-Werte können kommentiert bleiben
  // КрФЛСК = 'КрФЛСК', // Russisch
  // ЛDTFC = 'ЛDTFC', // Ukrainisch
  // KpDTCK = 'KpDTCK', // Weißrussisch
  // ŞVKFA = 'ŞVKFA', // Türkisch
  // KHWCS = 'KHWCS', // ???
  // KHWGJ = 'KHWGJ' // ???
  // KDVSJ = "KDVSJ", // Tschechisch, Slowakisch
  // KVBFH = "KVBFH", // Ungarisch
  // KHWGS = "KHWGS", // Polnisch
  // MDTFK = "MDTFK", // Albanisch
  // PBΠAΙ = "PBΠAΙ", // Griechisch
  // KVLFH = "KVLFH", // Ungarisch
  // KDTLZ = "KDTLZ", // Lettisch
  // RBCED = "RBCED", // Irisch
}

export const NotationNameMap: { [key in NotationName]: string[] } = {
  [NotationName.KDTLS]: ['K', 'D', 'T', 'L', 'S'],
  [NotationName.KDTLP]: ['K', 'D', 'T', 'L', 'P'],
  [NotationName.KDTLR]: ['K', 'D', 'T', 'L', 'R'],
  [NotationName.KDTLH]: ['K', 'D', 'T', 'L', 'H'],
  [NotationName.KDHBR]: ['K', 'D', 'H', 'B', 'R'],
  [NotationName.KDTFS]: ['K', 'D', 'T', 'F', 'S'],
  [NotationName.KQRBN]: ['K', 'Q', 'R', 'B', 'N'],
  [NotationName.RDTFC]: ['R', 'D', 'T', 'F', 'C'],
  [NotationName.RDTBC]: ['R', 'D', 'T', 'B', 'C'],
  [NotationName.RDTAC]: ['R', 'D', 'T', 'A', 'C'],
  [NotationName.RDTNC]: ['R', 'D', 'T', 'N', 'C'],
  [NotationName.RDTAS]: ['R', 'D', 'T', 'A', 'S']

  // КрФЛСК: ['Кр', 'Ф', 'Л', 'С', 'К'], // Russisch
  // ЛDTFC: ['Л', 'D', 'T', 'F', 'C'], // Ukrainisch
  // KpDTCK: ['Kp', 'D', 'T', 'C', 'K'], // Weißrussisch
  // ŞVKFA: ['Ş', 'V', 'K', 'F', 'A'], // Türkisch
  // KHWCS: ['K', 'H', 'W', 'C', 'S'], // ???
  // KHWGJ: ['K', 'H', 'W', 'G', 'J'], // ???
  // KDVSJ: ['K', 'D', 'V', 'S', 'J'], // Tschechisch, Slowakisch
  // KVBFH: ['K', 'V', 'B', 'F', 'H'], // Ungarisch
  // KHWGS: ['K', 'H', 'W', 'G', 'S'], // Polnisch
  // MDTFK: ['M', 'D', 'T', 'F', 'K'], // Albanisch
  // PBΠAΙ: ['P', 'B', 'Π', 'A', 'Ι'], // Griechisch
  // KVLFH: ['K', 'V', 'L', 'F', 'H'], // Ungarisch
  // KDTLZ: ['K', 'D', 'T', 'L', 'Z'], // Lettisch
  // RBCED: ['R', 'B', 'C', 'E', 'D'], // Irisch
};

export function getNotationNameFromLetters(letters: string): NotationName {
  let remainingNotations: NotationName[] = Object.values(NotationName);

  for (let i = 0; i < 5; i++) {
    const filtered = remainingNotations.filter((notation) =>
      notation.includes(letters[i])
    );

    if (filtered.length === 0) {
      return remainingNotations[0];
    }

    remainingNotations = filtered;

    if (filtered.length === 1) {
      return filtered[0];
    }
  }

  return remainingNotations[0];
}

enum FigureSigns {
  Kp = 'Kp', // Muss vor dem K stehen, da sonst Kp sonst immer als K erkannt wird
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  P = 'P',
  Q = 'Q',
  R = 'R',
  S = 'S',
  T = 'T',
  V = 'V',
  W = 'W',
  Z = 'Z',
  Ф = 'Ф',
  Л = 'Л',
  Ş = 'Ş',
  Π = 'Π'
}

// Bilde ein Regex aus den Figuren
const FIGURE_CHARACTER = new RegExp(Object.values(FigureSigns).join('|'), 'g');

/**
 * Ermittelt aus den erkannten Zugtexten automatisch die Notation, die der Schreiber verwendet hat.
 */
export function detectNotation(moves: string[]): NotationName {
  // Ermittle über alle Sheets alle Zugbilder die mit mindestens 90% erkannt wurden.
  const letters: string[] =
    moves
      .filter(
        (char) =>
          char !== 'O' && char.charCodeAt(0) >= 65 && char.charCodeAt(0) <= 90
      )
      .join('')
      .match(FIGURE_CHARACTER) ?? [];

  // Zähle die Häufigkeit der gleichen Matches (Ein Match kann auch aus 2 Buchstaben bestehen):
  const figureCounts = letters.reduce(
    (counts: { [key: string]: number }, figure: string) => {
      counts[figure] = (counts[figure] || 0) + 1;
      return counts;
    },
    {}
  );
  // Sortiere figureCounts absteigend nach Häufigkeit und nimm die 5 häufigsten
  const mostCommon = Object.entries(figureCounts)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 5)
    .map((entry: [string, number]) => ({
      figure: entry[0],
      count: entry[1]
    }));

  let remainingNotations: NotationName[] = Object.values(NotationName);

  for (let i = 0; i < Math.min(5, mostCommon.length); i++) {
    const filtered = remainingNotations.filter((notation) =>
      NotationNameMap[notation].includes(mostCommon[i].figure)
    );

    if (filtered.length === 0) {
      break;
    }

    remainingNotations = filtered;

    if (filtered.length === 1) {
      break;
    }
  }

  return remainingNotations[0];
}
