import { GameLogic } from "pgn-app-client";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import GameTitle from "../shared/components/GameTitle";
import MobileWrapper from "../shared/components/layout/MobileWrapper";
import ViewHeading from "../shared/components/views/ViewHeading";
import useTabs from "../shared/hooks/useTabs";
import StepBreadcrumbs from "../shared/stepview/StepBreadcrumbs";
import { useGameDataContext } from "../shared/stepview/context/GameDataProvider";
import TabProvider from "../shared/stepview/context/TabProvider";
import UploadPreview from "../upload/components/UploadPreview";
import ChooseLastMove from "./components/ChooseLastMove";
import EditMoves from "./components/EditMoves";
import PGNButtons from "./components/buttons/PGNButtons";
import useGameLogic from "./hooks/useGameLogic";

function PGNEdit() {
    const { t } = useTranslation();

    const {
        player_1,
        player_2,
        gameLogic,
        pgnString,
        expandMobileOverlay,
        setExpandMobileOverlay,
        setGameLogic,
        gameLogicSnapshot,
        setLoading,
    } = useGameDataContext();
    const { updateGameLogic, lastMoveSelected } = useGameLogic();

    const tabs = useTabs();

    useEffect(() => {
        if (gameLogic === null && player_1.data.length > 0) {
            setLoading(true);

            setTimeout(() => {
                const logic = new GameLogic(
                    player_1.data,
                    player_2.data,
                    updateGameLogic,
                    pgnString
                );

                setGameLogic(logic);
                setLoading(false);
            }, 10);
        }
    }, [
        player_1,
        player_2,
        gameLogic,
        pgnString,
        setGameLogic,
        updateGameLogic,
        setLoading,
    ]);

    /**
     * Toggle the {@link expandMobileOverlay} state.
     */
    const toggleOverlay = () => {
        setExpandMobileOverlay(!expandMobileOverlay);
    };

    return (
        <TabProvider>
            <div className="flex flex-col gap-1 tablet:gap-4 tablet:grid tablet:grid-cols-2 tablet:grid-flow-row-dense">
                <div className="flex flex-col gap-2">
                    <GameTitle />
                    <StepBreadcrumbs />
                    {!expandMobileOverlay && (
                        <ViewHeading
                            heading={t("step3.title")}
                            hintTitle={t("hint.step3.title")}
                            hintContent={t("hint.step3.content")}
                        />
                    )}
                    {gameLogicSnapshot !== null &&
                        (lastMoveSelected ? <EditMoves /> : <ChooseLastMove />)}
                </div>
                {
                    <MobileWrapper
                        heightCls={
                            expandMobileOverlay
                                ? "justify-between"
                                : ""
                        }
                        className="sm:justify-self-end sm:row-[1_/_span_2] sm:col-[2_/_span_1]"
                    >
                        <UploadPreview
                            tabs={tabs}
                            className={
                                "tablet:max-h-full items-center overflow-hidden self-end tablet:flex flex-col mb-0 tiny:mb-8 tablet:mb-0 " +
                                (expandMobileOverlay ? "flex" : "hidden")
                            }
                        />
                        <PGNButtons mobile toggle={toggleOverlay} />
                    </MobileWrapper>
                }
            </div>
        </TabProvider>
    );
}

export default PGNEdit;
