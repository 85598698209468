/**
 * Mobile Wrapper for the main buttons for each view.
 * The {@link MobileWrapper} sticky at the bottom of the page in the mobile view.
 * @param {React.ReactNode} props.children
 * @param {string} props.className
 * @returns mobile wrapper component
 */
function MobileWrapper({ children, className = "", heightCls = "" }) {
    const height = heightCls ? heightCls : "h-36";

    return (
        <div
            className={[
                "justify-start flex flex-col gap-2 z-10 tablet:z-0 fixed tablet:static",
                "max-w-screen tablet:w-full p-4 pt-5 tablet:p-0 bg-white bottom-0 tablet:bottom-auto",
                "right-0 tablet:right-auto left-0 tablet:left-auto rounded-br-none rounded-bl-none",
                "rounded-xl tablet:rounded-none shadow-lg-top tablet:shadow-none tablet:h-full",
                height,
                className,
            ].join(" ")}
        >
            {children}
        </div>
    );
}

export default MobileWrapper;
