import dayjs from "dayjs";
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useAppContext } from "../../../AppProvider";
import { getUserData } from "../../../api/PGNVariables";
import useFolders from "../../../api/useFolders";
import { useGameCacheContext } from "./GameCacheProvider";
/**
 * @typedef {Object} PlayerDef
 * @property {Blob[]} files
 * @property {{
 *      boxes: [{bw: number, gray: number, h: number, w: number, x: number, y: number}],
 *      notations: [{models:
 *          [{
 *              name: string,
 *              halfMoves: [{
 *                  r: [{t: string, p: number}]
 *              }]
 *          }],
 *          notation: string
 *      }],
 *      version: number
 *  }[]} data
 */

/**
 * @typedef {Object} MetaDataDef
 * @property {string} event
 * @property {string} location
 * @property {number} dateRound
 * @property {number} round
 * @property {number} board
 * @property {string} white
 * @property {string} black
 * @property {string} result
 */

/**
 * @type {{
 *  player_1: PlayerDef,
 *  player_2: PlayerDef,
 *  metaData: MetaDataDef,
 *  setMetaData: Function,
 *  updateMetaData: Function(update: Object),
 *  folder: number,
 *  setFolder: Function,
 *  gameLogic: {}|null,
 *  setGameLogic: Function,
 *  gameLogicSnapshot: {}|null,
 *  setGameLogicSnapshot: Function,
 *  expandMobileOverlay: boolean,
 *  setExpandMobileOverlay: Function,
 *  pgnString: string,
 *  setPgnString: Function,
 *  folders: {label: string, value: number, _header_data_sets: [], _selected_header_data: []}[],
 *  getHeaderDataSets: Function,
 *  gameId: string|null,
 *  setGameId: Function,
 *  loading: boolean,
 *  setLoading: Function,
 * }}
 */
const GameDataContext = createContext();

const playerDefault = {
    files: [],
    data: [],
};

/**
 * Provider (context) for the step view process.
 * @param {React.ReactNode} children
 * @returns {GameDataContext} component
 */
export const GameDataProvider = ({ children }) => {
    const [player_1, setPlayer1] = useState(
        /** @type {PlayerDef} */ (playerDefault)
    );
    const [player_2, setPlayer2] = useState(
        /** @type {PlayerDef} */ (playerDefault)
    );
    const [metaData, setMetaData] = useState(
        /** @type {MetaDataDef} */
        (
            () => {
                let date = dayjs();
                date = date.hour(6);
                date = date.minute(0);
                date = date.second(0);

                return {
                    dateRound: date.unix(),
                    result: "*",
                };
            }
        )
    );
    const [folder, setFolder] = useState(
        /** @type {Number} */
        (
            () => {
                const urlParams = new URLSearchParams(window.location.search);
                const urlFolder = urlParams.get("folder")
                    ? Number(urlParams.get("folder"))
                    : NaN;
                const userSelFolder = getUserData()["selectedFolderId"];
                const userFolder = getUserData()["defaultFolderId"][0];
                const userId = userSelFolder
                    ? Number(userSelFolder)
                    : userFolder;
                return !isNaN(urlFolder) ? urlFolder : userId;
            }
        )
    );
    const [gameLogic, setGameLogic] = useState(/** @type {{}|null} */ (null));
    const [gameLogicSnapshot, setGameLogicSnapshot] = useState(
        /** @type {{}|null} */ (null)
    );

    const [expandMobileOverlay, setExpandMobileOverlay] = useState(false);
    const [pgnString, setPgnString] = useState("");

    const [gameId, setGameId] = useState(/** @type {String|null} */ (null));
    const [loading, setLoading] = useState(false);

    const { folders, sharedFolders } = useFolders();
    const { cache, cacheLoad } = useGameCacheContext();
    const { setShared, user } = useAppContext();

    useEffect(() => {
        setLoading(cacheLoad);
        if (cacheLoad === false && Object.keys(cache).length > 0) {
            if (cache.metaData) {
                setMetaData(cache.metaData);
            }

            if (cache.player_1) {
                setPlayer1({ ...playerDefault, ...cache.player_1 });
            }
            if (cache.player_2) {
                setPlayer2({ ...playerDefault, ...cache.player_2 });
            }
            setGameId(cache.gameId);
            setPgnString(cache.pgnString ? cache.pgnString : "");

            if (cache.parent > 0) {
                setFolder(cache.parent);
            }

            if (!isNaN(cache.author) && cache.author !== user.id) {
                setShared({
                    author: cache.author,
                    parent: cache.parent,
                });
            }
        }
    }, [cache, cacheLoad, user, setShared]);

    /**
     * @param {{any}} value
     */
    const updatePlayer = useCallback(
        (player, value) => {
            if (player === "player_1") {
                setPlayer1({ ...player_1, data: [], ...value });
            } else if (player === "player_2") {
                setPlayer2({ ...player_2, data: [], ...value });
            }
        },
        [player_1, player_2]
    );

    /**
     * @param {{any}} update
     */
    const updateMetaData = useCallback(
        (update) => {
            setMetaData({ ...metaData, ...update });
        },
        [metaData, setMetaData]
    );

    /**
     * @returns {[{any}]}
     */
    const getHeaderDataSets = useCallback(() => {
        const allFolders = [...sharedFolders, ...folders];
        if (allFolders.length > 0) {
            const validFolder = allFolders.find((f) => f.value === folder);
            return validFolder ? validFolder["_header_data_sets"] : [];
        }
        return [];
    }, [folders, sharedFolders, folder]);

    const context = useMemo(
        () => ({
            player_1,
            player_2,
            setPlayer1,
            setPlayer2,
            updatePlayer,
            metaData,
            setMetaData,
            updateMetaData,
            folder,
            setFolder,
            gameLogic,
            setGameLogic,
            gameLogicSnapshot,
            setGameLogicSnapshot,
            pgnString,
            setPgnString,
            expandMobileOverlay,
            setExpandMobileOverlay,
            folders,
            sharedFolders,
            getHeaderDataSets,
            gameId,
            setGameId,
            loading,
            setLoading,
        }),
        [
            player_1,
            player_2,
            updatePlayer,
            metaData,
            updateMetaData,
            folder,
            folders,
            sharedFolders,
            gameLogic,
            setGameLogic,
            gameLogicSnapshot,
            setGameLogicSnapshot,
            pgnString,
            expandMobileOverlay,
            setPgnString,
            getHeaderDataSets,
            gameId,
            loading,
        ]
    );

    return (
        <GameDataContext.Provider value={context}>
            {children}
        </GameDataContext.Provider>
    );
};

/**
 * @returns {GameDataContext}
 */
export const useGameDataContext = () => {
    return useContext(GameDataContext);
};

export default GameDataContext;
