import { MoveShift } from './MoveShift';
import { GameLogic } from './GameLogic';
import { RowIndex } from './RowIndex';
import { RowLocation } from './RowLocation';
import { SheetIndex } from './SheetIndex';
import { Writer } from './Writer';
import { Sheet } from './recognition';
import { HalfMove } from './HalfMove';
import { detectNotation, NotationName } from './NotationName';

/**
 * Dieses Objekt kapselt die Schreiber-spezifischen Daten eines Spiels ({@linkcode GameLogic})
 */
export class GameLogicWriter {
  readonly #gameLogic: GameLogic;
  /**
   * @private
   * Erstellt ein neues {@linkcode GameLogicWriter}-Objekt für das angegebene Spiel und
   * Schreiber.
   * @param gameLogic Verweis auf das GameLogic-Objekt zu dem dieses Objekt gehört.
   * @param writer Schreiber für den dieses Objekt gilt.
   */
  constructor(
    gameLogic: GameLogic,
    /**
     * Angabe für welchen Schreiber dieses Objekt generiert werden soll
     */
    public readonly writer: Writer,

    /**
     * Liste der Blätter von diesem Schreiber
     */
    public readonly sheets: readonly Sheet[]
  ) {
    this.#gameLogic = gameLogic;
    this.notation = this.#detectNotation();
  }

  /**
   * Notation welche der Anwender verwendet hat.
   */
  public readonly notation: NotationName;

  /**
   * Backing Field für {@linkcode lastMoveFixed}
   */
  #lastMoveFixed: RowLocation | undefined;

  /**
   * @hidden
   * @private
   *
   * Liefert die {@linkcode RowLocation} des von Anwender bewusst gesetzten
   * letzten erfassten Zug des zugehörigen Schreibers.
   */
  get lastMoveFixed() {
    return this.#lastMoveFixed;
  }

  /**
   * @hidden
   *
   * Setter zu {@linkcode get lastMoveFixed}.
   */
  set lastMoveFixed(value: RowLocation | undefined) {
    this.setLastMoveFixedInternal(
      value,
      this.#gameLogic.halfMoves != undefined
    );
  }

  /**
   * Ermittelt aus den erkannten Zugtexten automatisch die Notation, die der Schreiber verwendet hat.
   */
  #detectNotation(): NotationName {
    // Ermittle über alle Sheets alle Zugbilder die mit mindestens 99% erkannt wurden.
    return detectNotation(
      this.sheets
        .map((x) => x.notations[0].models[0])
        .flatMap((x) => x.halfMoves)
        .flatMap((x) => x.r[0])
        .filter((x) => x.p >= 99)
        .map((x) => x.t)
    );
  }

  /**
   * @private
   *
   * Interne Hilfsmethode zum setzen des {@linkcode lastMoveFixed}-Feldes, mit der Möglichkeit,
   * den anschließenden Refresh / Calculate zu unterbinden. Das macht dann Sinn wenn danach noch
   * weitere API-Aufrufe folgen und keine mehrfach-Refreshs ausgelist werden sollen.
   *
   * @param value Neuer Wert für das Feld. ```undefined``` Falls der Wert zurückgesetzt werden soll.
   * @param recalculate Gibt
   */
  setLastMoveFixedInternal(
    value: RowLocation | undefined,
    recalculate: boolean
  ) {
    if (value != this.#lastMoveFixed) {
      this.#lastMoveFixed = value;

      if (recalculate) this.#gameLogic.halfMoves[0].calculate();
    }
  }

  /**
   * Backing Field für {@linkcode lastMoveCalculated}
   */
  #lastMoveCalculated: RowLocation | undefined;

  /**
   * Liefert den Vorschlag für die Zelle mit dem letzten geschriebenen Halbzug des Schreibers.
   *
   * @hidden
   */
  get lastMoveCalculated(): RowLocation | undefined {
    return this.#lastMoveCalculated;
  }

  /**
   * Gibt an ob im aktuellen Spielzustand die Daten des Schreibers zu diesen Spiel
   * angezeigt / aktiv sein sollen.
   *
   * Aktuell wird diese Eigenschaft für einen speziellen Use-Case eingesetzt: Während des
   * setzen des letzten Zuges der beiden Schreiber soll die Spalte des zweilig anderen
   * Schreibers inaktiv und ausgegraut dargestellt werden. Daher muss die UI diese
   * Eigenschaft an verschiedenen Stellen auswerten.
   */
  public get show() {
    return (
      this.#gameLogic.gameEndIsDefined() ||
      (this.writer == 1 && this.lastMoveFixed == undefined) ||
      (this.writer == 2 && this.#gameLogic.writer1.lastMoveFixed != undefined)
    );
  }

  /**
   * @private
   *
   * Versucht die letzte Zelle des Schreibers zu ermitteln in der noch eine Zug-Beschreibung
   * angegben ist. Diese Logik ist nicht perfekt.
   */
  calculateLastMoveProposalForWriter() {
    const sheet = this.sheets.slice(-1)[0];

    // Nimm vom Schreiber nur die beste Notation und davon nur das S/W-Modell
    const halfMovesGray = sheet.notations[0].models.filter(
      (x) => x.name == 'GRAY'
    )[0].halfMoves;
    let badCounter = 0;
    let goodCounter = 0;
    let candidate = 0;
    let toleranceBad = 1;
    for (let index = sheet.boxes.length - 1; index >= 0; index--) {
      const pGray = halfMovesGray.filter((x, i) => i == index)[0].r[0].p;

      if (pGray >= 99) toleranceBad = 3; // Wenn einmal ein 99-Prozent Treffer da war, dann bei den näcshten nicht so pingelich sein...

      if (pGray >= 80) {
        goodCounter++;
        if (goodCounter == 1) {
          candidate = index;
          badCounter = 0;
        } else if (goodCounter > 3) break;
      } else {
        badCounter++;
        if (badCounter > toleranceBad) {
          goodCounter = 0;
          toleranceBad = 1; // Falls dann doch schlecht war, dann wieder pingeliger sein..
        }
      }
    }

    this.#lastMoveCalculated = new RowLocation(
      (this.sheets.length - 1) as SheetIndex,
      candidate as RowIndex
    );
  }

  /**
   * @private
   *
   * Ermittelt die nächste RowLocation nach der angegebenen.
   *
   * Theoretisch könnten beide Spieler unterschiedliche Formular mit unterschiedlicher Anzahl Zügen
   * pro Blatt verwenden. Es ist auch denkbar dass die Anzahl der Züge pro Blatt unterschiedlich ist,
   * z.B. dass die erste Seite wegen den Kopfdaten weniger Seiten hat als die weiteren Züge...
   *
   * @param baseRowLocation Die Basis- RowLocation. Für den ersten Zug kann ```undefined```
   * oder ```null```übergeben werden, dann wird die erste Zelle (0/0) zurückgeliefert...
   * @returns Liefert die nächste RowLocation, oder ``null``` wenn das nicht möglich ist.
   */
  public getRelativeRowLocation(
    baseRowLocation: RowLocation | undefined,
    shift: MoveShift
  ): RowLocation | null {
    if (baseRowLocation) {
      if (shift == 0) return baseRowLocation;

      const newRowIndex = baseRowLocation.rowIndex - shift;
      const cellCount = this.sheets[baseRowLocation.sheetIndex].boxes.length;
      if (newRowIndex < 0 && baseRowLocation.sheetIndex == 0) return null;
      else if (
        newRowIndex > cellCount - 1 &&
        baseRowLocation.sheetIndex >= this.sheets.length - 1
      )
        return null;
      else if (newRowIndex < 0)
        return new RowLocation(
          (baseRowLocation.sheetIndex - 1) as SheetIndex,
          (this.sheets[baseRowLocation.sheetIndex - 1].boxes.length -
            shift) as RowIndex
        );
      else if (newRowIndex > cellCount - 1)
        return new RowLocation(
          (baseRowLocation.sheetIndex + 1) as SheetIndex,
          (this.sheets[baseRowLocation.sheetIndex].boxes.length -
            newRowIndex) as RowIndex
        );
      else
        return new RowLocation(
          baseRowLocation.sheetIndex,
          (baseRowLocation.rowIndex - shift) as RowIndex
        );
    } else if (shift == -1) return new RowLocation(0, 0);
    else return null;
  }

  /**
   * Hilfsvariable welche den letzten Halbzug des Schreibers speichert.
   *
   * @private
   */
  public endAt?: HalfMove;
}
