import { useEffect, useRef } from "react";

/**
 * @param {React.ReactNode} props.children
 * @param {string} props.className additional css classes
 * @param {boolean} props.focus RowItem should be focused
 * @returns Row-item container
 */
function RowItem({ children, className = "", focus, ...otherProps }) {
    /** @type {React.MutableRefObject<HTMLLIElement|null>} */
    const listRef = useRef(null);

    useEffect(() => {
        if (focus && listRef && listRef.current) {
            listRef.current?.scrollIntoView({
                block: "nearest",
            });
        }
    }, [focus]);

    const props = {
        className: [
            "flex flex-wrap xl:flex-nowrap gap-2 pl-1 pb-1 pt-4 border-t border-gray-300 items-center",
            className,
        ].join(" "),
        ...otherProps,
    };
    return (
        <li {...props} ref={listRef}>
            {children}
        </li>
    );
}

export default RowItem;
